<template>
  <div class="bg-v3-gray-50 bg-opacity-10 rounded-xl h-full p-2 col-span-2 overflow-hidden dark:text-white"
       v-if="details || loadingDetails">
    <div v-if="details" class="text-center sticky top-0 h-full grid grid-cols-3 w-ful gap-2 overflow-hidden">
      <div class="w-full rounded-xl bg-v3-base-500 dark:bg-v3-gray-900 h-full overflow-y-auto">
        <div class="flex flex-col h-full">
          <div class="m-2">
            <div class="text-center">
              <p class="text-2xl mt-6">Settings</p>
              <p class="text-v3-800 opacity-60 mb-20">HQ App</p>

              <header-link class="my-4" @click="changeTab('settings')" :isActive="tabOpen === 'settings'" activeClass="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20">
                <template v-slot:title>
                  <div class="text-left">
                    Dashboard Adverts
                  </div>
                </template>
              </header-link>
                <header-link class="my-4" @click="changeTab('app_adverts')" :isActive="tabOpen === 'app_adverts'" activeClass="bg-v3-gray-50 bg-opacity-20 dark:bg-v3-gray-50 dark:bg-opacity-20">
                    <template v-slot:title>
                        <div class="text-left">
                            App Adverts
                        </div>
                    </template>
                </header-link>
            </div>
          </div>

        </div>
      </div>
      <div class="w-full rounded-xl bg-v3-base-500 dark:bg-v3-gray-900 h-full col-span-2 overflow-y-auto text-left">
        <div class="p-2 h-full w-full">
            <div v-if="tabOpen==='settings'">
                <hq-settings-pagination :entity="details" :editing="editing" :collapsable="false" class="my-2"
                                       :action="updateAction"
                                       :updateActionData="updateActionData"  @editClicked="editDetails" @saveClicked="saveDetails" @success="formSuccess"></hq-settings-pagination>
            </div>
            <div v-if="tabOpen==='app_adverts'">
                <hq-app-advert-settings-pagination :entity="details" :editing="editing" :collapsable="false" class="my-2"
                                        :action="updateAction"
                                        :updateActionData="updateActionData"  @editClicked="editDetails" @saveClicked="saveDetails" @success="formSuccess"></hq-app-advert-settings-pagination>
            </div>

        </div>
      </div>
    </div>
    <loading v-else class="sticky top-0"></loading>

      <modal v-if="showModal" @removeFromDisplay="showModal = false" @perform="showModal = false" title="Unsaved Changes" confirmationString="" :has-confirmation-action="false">
          Please save changes before changing page.
      </modal>
  </div>
</template>
<script>
import BlankHeader from "@/v3/components/headers/BlankHeader.vue"
import HeaderLink from "@/v3/components/headers/HeaderLink.vue"
import Loading from '@/components/Loading'
import OrderActivityFeed from "@/v3/components/OrderActivityFeed.vue"
import OrderAttachmentsPagination from "@/v3/components/pagination/OrderAttachmentsPagination.vue"
import OrderBasicDetailsPagination from "@/v3/components/pagination/OrderBasicDetailsPagination.vue"
import OrderContractInfoPagination from "@/v3/components/pagination/OrderContractInfoPagination.vue"
import OrderItemsPagination from "@/v3/components/pagination/OrderItemsPagination.vue"
import OrderSchedulePagination from "@/v3/components/pagination/OrderSchedulePagination.vue"
import StatusBadge from "@/components/StatusBadge.vue"
import {mapGetters} from "vuex";
import fileDownload from "js-file-download";
import DownloadPdfInline from "@/v3/components/DownloadPdfInline.vue";
import OrderInvoicesPagination from "@/v3/components/pagination/OrderInvoicesPagination.vue";
import OrderCreditNotesPagination from "@/v3/components/pagination/OrderCreditNotesPagination.vue";
import ConfirmationAction from "@/v3/components/forms/ConfirmationAction.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import EmailPreviewForm from "@/v3/components/forms/EmailPreviewForm.vue";
import OrderQuotationsPagination from "@/v3/components/pagination/OrderQuotationPagination.vue";
import SelectQuoteForm from "@/v3/components/forms/SelectQuoteForm.vue";
import NewCreditNoteForm from "@/v3/components/forms/NewCreditNoteForm.vue";
import ReconcileOrderForm from "@/v3/components/forms/ReconcileOrderForm.vue";
import GoodsInNoteForm from "@/v3/components/forms/GoodsInNoteForm.vue";
import ReturnsForm from "@/v3/components/forms/ReturnsForm.vue";
import OrderAttachments from "@/v3/components/pagination/OrderAttachments.vue";
import OrderNotes from "@/v3/components/pagination/OrderNotes.vue";
import SplitOrderForm from "@/v3/components/forms/SplitOrderForm.vue";
import UpdateScheduledFileForm from "@/v3/components/forms/UpdateScheduledFileForm.vue";
import ReadyForQuoteForm from "@/v2/components/forms/ReadyForQuoteForm.vue";
import ReviewQuotesForm from "@/v2/components/forms/ReviewQuotesForm.vue";
import QaDetailsPagination from "@/v3/components/pagination/QaDetailsPagination.vue";
import HqSettingsPagination from "@/v3/components/pagination/HqSettingsPaginations.vue";
import HqAppAdvertSettingsPagination from "@/v3/components/pagination/HqAppAdvertSettingsPaginations.vue";
import Modal from "@/v3/components/modals/Modal.vue";

export default {
  name: 'HqSettingsPanel',
  components: {
      Modal,
      HqAppAdvertSettingsPagination,
      HqSettingsPagination,
      QaDetailsPagination,
      ReviewQuotesForm, ReadyForQuoteForm,
      UpdateScheduledFileForm, SplitOrderForm,
      OrderNotes,
      OrderAttachments,
      ReturnsForm,
      GoodsInNoteForm,
      ReconcileOrderForm, NewCreditNoteForm,
      SelectQuoteForm,
      OrderQuotationsPagination,
      EmailPreviewForm,
       FormAction, ConfirmationAction,
      OrderCreditNotesPagination,
      OrderInvoicesPagination,
      DownloadPdfInline,
    BlankHeader,
    HeaderLink,
    Loading,
    OrderActivityFeed,
    OrderAttachmentsPagination,
    OrderBasicDetailsPagination,
    OrderContractInfoPagination,
    OrderItemsPagination,
    OrderSchedulePagination,
    StatusBadge
  },
    data(){
      return {
          editing: false,
          editingOrderItems: false,
          tabOpen: 'settings',
          showModal: false
      }
    },
    props:{
      details: {
          type: Object,
          default: null
      },
        hideTable: {
          type: Boolean,
            default: false
        },
        loadingDetails:{
          type: Boolean,
            default: false
        },
        hasTable:{
          type: Boolean,
            default: true
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        initials() {
            if (this.orderUser.entity && this.orderUser.entity.properties.get('name')) {
                return this.orderUser.entity.properties.get('name')
                    .replace(/[^a-zA-Z- ]/g, "")
                    .match(/\b\w/g)
                    .join("");
            }
        },
        updateAction() {
            if (!this.details) {
                return null;
            }
            return this.details.actions.filter(action => action.name === 'update-dashboard').first()
        },
        updateActionData() {
            if (!this.updateAction && this.updateAction.fields) {
                return {};
            }
            var payload = {};
            this.updateAction.fields.forEach(function (field) {
                if (field.value !== undefined) payload[field.name] = field.value;
            });
            return payload;
        },
    },
    methods:{
        editDetails(){
            this.editing = true;
        },
        saveDetails() {
            if(!this.submittingModal) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submittingModal = true;
                this.updateAction.perform(this.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submittingModal = false;
                        return;
                    }
                    this.editing = false;
                    this.submittingModal = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.formSuccess(res);
                }).catch(error => {
                    this.submittingModal = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        async saveItems(){
            if (!this.orderItemsSubmitting) {
                this.orderItemsSubmitting = true;
                this.$refs.orderItems.updateItems().then(res => {
                    this.editingOrderItems = false;
                    this.orderItemsSubmitting = false;
                    let entity = {'entity': this.details};
                    this.$emit('setLoading');
                    this.$emit('getDetails', entity);

                    this.$store.dispatch(
                        "setMessage",
                        'The order was saved successfully'
                    );
                }).catch(error => {
                    if(error.status !== 422) {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$setLaravelValidationErrorsFromResponse(JSON.parse(error.error.response.text), error.scope);
                    this.orderItemsSubmitting = false;
                })
            }
        },
        removeItem(){
            let entity = {'entity': this.details};
            this.$emit('setLoading');
            this.$emit('getDetails', entity);
        },
        getTitle(name, title){
            if(name === 'mark-as-ready-for-quote'){
                return 'Get Quote';
            }
            if(name === 'mark-as-take-off'){
                return 'Get Take Off';
            }
            if(name === 'delete-order'){
                return 'Delete';
            }
            if(name === 'send-quotation-request'){
                return 'Quote Req.'
            }
            if(name === 'mark-as-quotes-received'){
                return 'Quotes Received';
            }
            if(name === 'create-return-request'){
                return 'Return';
            }
            if(name === 'approve-quotes'){
                return 'Approve';
            }
            if(name === 'reject-quotes'){
                return 'Reject';
            }
            return title;
        },
        getColourType(action, index){
            if(action === 'delete-order' || action.includes('reject') || action.includes('delete')){
                return 'negative';
            }
            if(action.includes('approve') || action.includes('accept')) {
                return 'positive';
            }
            return  index === 0 ? 'primary': 'secondary';
        },
        formSuccess(res){
            if(res.body) {
                let details = res.body;
                let entity = {'entity': details};
                this.$emit('getDetails', entity);
            }
        },
        changeTab(tabName){
            if(this.editing !== false){
                this.showModal = true;
            }else{
                this.tabOpen = tabName;
            }
        }
    }
}
</script>
