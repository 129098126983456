<template>
    <pagination title="Dashboard Adverts" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="saveClicked" header-colour-class="bg-transparent">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Advert File 1 <span class="text-xs italic">(ideal size: 192px x 192px)</span>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_file1"
                        :action="editing? action: null"
                        fieldId="advert_file1"
                        :showLabel="false"
                        v-model="file1">
                    </property-or-field>
                    <div v-show="errors.has('advert_file1')" class="text-red-600">{{ errors.first('advert_file1') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    <a v-if="editing" @click="file1 = 'remove'" class="cursor-pointer underline">Remove</a>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Advert Link 1
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_link1"
                        :action="editing ? action: null"
                        fieldId="advert_link1"
                        :showLabel="false"
                        v-model="updateActionData['advert_link1']">
                    </property-or-field>
                    <div v-show="errors.has('advert_link1')" class="text-red-600">{{ errors.first('advert_link1') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Advert File 2<span class="text-xs italic">(ideal size: 192px x 750px)</span>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_file2"
                        :action="editing? action: null"
                        fieldId="advert_file2"
                        :showLabel="false"
                        v-model="file2">
                    </property-or-field>
                    <div v-show="errors.has('advert_file2')" class="text-red-600">{{ errors.first('advert_file2') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    <a v-if="editing" @click="file2 = 'remove'" class="cursor-pointer underline">Remove</a>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Advert Link 2
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_link2"
                        :action="editing ? action: null"
                        fieldId="advert_link2"
                        :showLabel="false"
                        v-model="updateActionData['advert_link2']">
                    </property-or-field>
                    <div v-show="errors.has('advert_link2')" class="text-red-600">{{ errors.first('advert_link2') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Advert File 3<span class="text-xs italic">(ideal size: 192px x 192px)</span>
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_file3"
                        :action="editing? action: null"
                        fieldId="advert_file3"
                        :showLabel="false"
                        v-model="file3">
                    </property-or-field>
                    <div v-show="errors.has('advert_file3')" class="text-red-600">{{ errors.first('advert_file3') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    <a v-if="editing" @click="file3 = 'remove'" class="cursor-pointer underline">Remove</a>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Advert Link 3
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_link3"
                        :action="editing ? action: null"
                        fieldId="advert_link3"
                        :showLabel="false"
                        v-model="updateActionData['advert_link3']">
                    </property-or-field>
                    <div v-show="errors.has('advert_link3')" class="text-red-600">{{ errors.first('advert_link3') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Dashboard Advert File
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_file4"
                        :action="editing? action: null"
                        fieldId="advert_file4"
                        :showLabel="false"
                        v-model="file4">
                    </property-or-field>
                    <div v-show="errors.has('advert_file4')" class="text-red-600">{{ errors.first('advert_file4') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">
                    <a v-if="editing" @click="file4 = 'remove'" class="cursor-pointer underline">Remove</a>
                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    Dashboard Advert Link
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="advert_link4"
                        :action="editing ? action: null"
                        fieldId="advert_link4"
                        :showLabel="false"
                        v-model="updateActionData['advert_link4']">
                    </property-or-field>
                    <div v-show="errors.has('advert_link4')" class="text-red-600">{{ errors.first('advert_link4') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'HqSettingsPagination',
    data(){
        return{
            file1: this.updateActionData['advert_file1'],
            file2: this.updateActionData['advert_file2'],
            file3: this.updateActionData['advert_file3'],
            file4: this.updateActionData['advert_file4'],
        }
    },
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      action:{
        type: Object,
          default: null
      },
    editing:{
        type: Boolean,
        default: false
    },
      updateActionData:{
        type: Object
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      },
        saveClicked(){
            this.updateActionData['advert_file1'] = this.file1;
            this.updateActionData['advert_file2'] = this.file2;
            this.updateActionData['advert_file3'] = this.file3;
            this.updateActionData['advert_file4'] = this.file4;
            this.$emit('saveClicked');
        }
    }
}
</script>
