<template>
    <pagination title="App Adverts" :entities="entity" :collapsable="collapsable" :open="open" @toggleOpen="toggleOpen" :checkSize="false" :withEditIcon="true" :editing="editing" @editClicked="$emit('editClicked')" @saveClicked="$emit('saveClicked')" header-colour-class="bg-transparent">
        <template v-slot:items>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    App Advert File 1
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="app_advert_file1"
                        :action="editing? action: null"
                        fieldId="app_advert_file1"
                        :showLabel="false"
                        v-model="updateActionData['app_advert_file1']">
                    </property-or-field>
                    <div v-show="errors.has('app_advert_file1')" class="text-red-600">{{ errors.first('app_advert_file1') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    App Advert Link 1
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="app_advert_link1"
                        :action="editing ? action: null"
                        fieldId="app_advert_link1"
                        :showLabel="false"
                        v-model="updateActionData['app_advert_link1']">
                    </property-or-field>
                    <div v-show="errors.has('app_advert_link1')" class="text-red-600">{{ errors.first('app_advert_link1') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    App Advert File 2
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="app_advert_file2"
                        :action="editing? action: null"
                        fieldId="app_advert_file2"
                        :showLabel="false"
                        v-model="updateActionData['app_advert_file2']">
                    </property-or-field>
                    <div v-show="errors.has('app_advert_file2')" class="text-red-600">{{ errors.first('app_advert_file2') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    App Advert Link 2
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="app_advert_link2"
                        :action="editing ? action: null"
                        fieldId="app_advert_link2"
                        :showLabel="false"
                        v-model="updateActionData['app_advert_link2']">
                    </property-or-field>
                    <div v-show="errors.has('app_advert_link2')" class="text-red-600">{{ errors.first('app_advert_link2') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    App Advert File 3
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="app_advert_file3"
                        :action="editing? action: null"
                        fieldId="app_advert_file3"
                        :showLabel="false"
                        v-model="updateActionData['app_advert_file3']">
                    </property-or-field>
                    <div v-show="errors.has('app_advert_file3')" class="text-red-600">{{ errors.first('app_advert_file3') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
            <tr>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-6 text-left">
                    App Advert Link 3
                </td>
                <td class="whitespace-normal px-3 py-4 text-sm text-left">
                    <property-or-field
                        :properties="entity.properties"
                        property-key="app_advert_link3"
                        :action="editing ? action: null"
                        fieldId="app_advert_link3"
                        :showLabel="false"
                        v-model="updateActionData['app_advert_link3']">
                    </property-or-field>
                    <div v-show="errors.has('app_advert_link3')" class="text-red-600">{{ errors.first('app_advert_link3') }}</div>

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
                <td class="whitespace-normal px-3 py-4 text-sm">

                </td>
            </tr>
        </template>
    </pagination>
</template>
<script>
import Pagination from "@/v3/components/pagination/Pagination.vue";
import axios from "axios";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading.vue";
import PropertyOrField from "@/components/PropertyOrField.vue";

export default {
    inject: ["$validator"],
  name: 'HqAppAdvertSettingsPagination',
    components: {PropertyOrField, Loading, Pagination},
  props: {
    entity: {},
      collapsable:{
        type: Boolean,
        default: false,
      },
      open:{
        type: Boolean,
        default: true
      },
      action:{
        type: Object,
          default: null
      },
    editing:{
        type: Boolean,
        default: false
    },
      updateActionData:{
        type: Object
      }
  },
    methods:{
      toggleOpen(){
          this.$emit('toggleOpen');
      }
    }
}
</script>
